// .leaflet-map-pane .leaflet-popup-tip-container {
//     background-image: url('https://maps.api.2gis.ru/2.0/img/DGCustomization__markerHover.png');
//     width: 22px;
//     height: 34px;
// }

.leaflet-popup-tip-container_svg,
.leaflet-retina .leaflet-popup-tip-container_svg {
    background-image: url('https://maps.api.2gis.ru/2.0/img/DGCustomization__markerHover.png');
    background-size: auto;
    fill: none;
}