.head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 50px 0px 30px 0px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
    }
    h3 {
        margin: 0;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }

    .SearchWrap {
        // border-radius: 5px;
        height: 40px;
        display: flex;
        // align-items: center;


        input {
            border: 1px #BCDDF6 solid;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-right: none;
            background-color: #f1f5f8;
            padding: 5px 15px;
            font-family: 'Gotham Pro';
            font-size: 16px;

            outline: none;
        }
        button {
            border: 1px solid #2e74e3;
            border-left: none;
            background-color: #2e74e3;
            padding: 5px 15px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #ffffff;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
}



.item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #BCDDF6;
    margin-bottom: 30px;
    transition: 0.1s;

    cursor: pointer;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    p {
        margin: 0;
    }

    img {
        @media (max-width: 768px) {
            width: 100% !important;
            height: auto !important;
        }
    }

    .date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 35px;

        @media (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 19px;
        }   

        svg {
            margin-right: 10px;
        }

        span {
            font-family: 'Gotham Pro';
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            vertical-align: middle;
            margin-top: 4px;
            white-space: nowrap;
        }
    }

    .text {
        .title {
            font-size: 20px;
            line-height: 22px;
            font-family: 'Gotham Pro Bold';
            margin-bottom: 19px;
            transition: 0.1s;
        }

        .description {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 30px;
            font-family: 'Gotham Pro';
            text-align: justify;
            white-space: pre-wrap;
            @media (max-width: 768px) {
                margin-bottom: 19px;
            }
        }
    }

    &:hover { 
        border-bottom: 1px solid #0078c8;
        .title {
            color: #0078c8;
        }
    }
}