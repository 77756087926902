.head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    margin-bottom: 40px;
    margin-top: 40px;

    @media (min-width: 1000px) and (max-width: 1270px) {
        flex-direction: column;

        align-items: start;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        align-items: start;
    }

    h3 {
        color: #047EC2;
    
        font-size: 24px;
        line-height: 28px;
    
        text-transform: uppercase;
    
        margin: 0;
    
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        @media (min-width: 1000px) and (max-width: 1270px) {
            margin-bottom: 20px;
        }
    }
}



.photosWrapper {
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;

  @media (min-width: 1700px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin-bottom: 40px;

}

.imgWrap {
    width: 100%;
    height: 250px;
}

img.mainImg {
    width: 100%;
  height: 100%;
  object-fit: cover;
/*   object-fit: contain; */
  object-position: center;
    cursor: pointer;

    margin-bottom: 30px;

    @media (max-width: 1000px) {
        width: 350px;
    }

    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.modalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba($color: #000000, $alpha: 0.9);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 10000;

    .modalContainer {
        width: 60vw;

        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        .loaderIcon {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 60px;
                margin-bottom: 40px;
            }
            span {
                cursor: pointer;
                color: #ffffff
            }
        }

        .close {
            position: absolute;
            top: -40px;
            right: -40px;
            width: 30px;
            cursor: pointer;
        }
        img {
            width: 100%;
        }
        .next,
        .prev {
            position: absolute;
            top: calc(50% - 10px);
            width: 20px;

            cursor: pointer;
        }
        .next {
            right: -70px;
        }
        .prev {
            left: -70px;
        }
    }
}