.avatar {
    display: none;
}

.UploadInputWrapper {
    display: flex;
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
    }
    .error {
        color: red;
        font-weight: 500;
        font-size: 15px;
    }
    .cropContainer {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;  

        background-color: #00000050;

        z-index: 3000;


        .cropWrapper {
            background-color: #f1f5f8;
            border: 1px solid black;
            display: flex;

            padding: 20px 20px;   

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
        }

        .cropOptions {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .btn_group {
                width: 100%;
                
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 768px) {
                    margin-left: -20px;
                    margin-top: 20px;
                    width: calc(100% + 20px);
                }
            }

            @media (max-width: 768px) {
                div {
                    width: 100%;
                }
            }
        }
    }
    .warnings {
        span {
            display: block;

            margin-top: 40px;
            font-family: 'Fira Sans Regular', sans-serif;

            &:first-child {
                font-size: 16px;
                color: #333333;

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: -20px;
                }
                }
            &:last-child {
                font-size: 14px;
                color: #757575;
                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 30px;
                }
            }
        }
        
    }

    
    
    .uploadLabel {
        display: flex;
        flex-direction: column;
    
        align-items: center;
        justify-content: center;
        width: 350px;
    
        min-height: 400px;
        max-height: 400px;
    
        margin-right: 30px;

        border: 1px solid #bcddf6;
    
        background-color: #f1f5f8;
    
        cursor: pointer;
    
        overflow: hidden;

        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            height: 400px;
        }
    
        img {
            width: 50px;
            height: 50px;
            margin-bottom: 30px;
        }
    
        .preview {
            height: 100%;
            width: 100%;
            // min-height: 400px;
            // max-height: 400px;
            // width: auto;

            background-size: cover;
            background-position: center top;
    
            margin: 0 auto;
        }

        .editBox {
            position: absolute;
            bottom: 10px;
            right: 10px;

            display: flex;
            align-items: center;
            justify-content: center;


            z-index: 1000;


            cursor: pointer;

            transition: .1s;

            &:hover {
                transform: scale(1.2);
            }
            
            img {
                margin-bottom: 0;  
                width: 90%; 
            }
        
        }
        .deleteBox {
            position: absolute;
            bottom: 10px;
            right: 10px;


            display: flex;
            align-items: center;
            justify-content: center;

        

            z-index: 1000;

            cursor: pointer;

            transition: .1s;

            &:hover {
                transform: scale(1.2);
            }
            
            img {
                margin-bottom: 0;   
                width: 90%;
                // height: 100%;
            }
        
        }

    
        span {
            position: relative;
            left: 0 !important;
    
            font-weight: 500;
            font-size: 24px;
    
            color: #0078c8;
            
        }
        &:hover {
            background-color: #bcddf6;
            
            span {
                color: #ffffff;
            }
        }
    }
}

