/* @font-face {
  font-family: "Fira Sans";
  src: url("./fonts/firasans/FiraSans-Regular.otf");
  font-style: normal;
} */

@font-face {
  font-family: "Gotham Pro";
  src: url("./fonts/GothamPro/gothampro.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro Medium";
  src: url("./fonts/GothamPro/gothampro_medium.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro Bold";
  src: url("./fonts/GothamPro/gothampro_bold.ttf");
  font-style: bold;
}

@font-face {
  font-family: "Nexa Bold";
  src: url("./fonts/Nexa/Nexa_Bold.ttf");
  font-style: bold;
}

@font-face {
  font-family: "Nexa Light";
  src: url("./fonts/Nexa/Nexa_Light.ttf");
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Gotham Pro";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

html {
  overflow-x: hidden;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  height: 100%;
}
html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

a {
  text-decoration: none;

  color: inherit;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

#root {
  height: 100%;
}

input[type=text], input[type=submit], textarea{
	-webkit-appearance: none;
} 
