.pageTitle {
    margin-bottom: 40px;
}

.videosWrapper {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 1700px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
    grid-gap: 15px;
}

.daysWrapper {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 40px;

    p {
        margin: 0;
        margin-bottom: 10px;
        margin-right: 20px;

        color: #95A8B6;

        cursor: pointer;

        box-sizing: border-box;

        &:hover {
            border-bottom: 1px solid #047EC2;
            margin-top: -1px;
        }
    }
}