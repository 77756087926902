.pagination {
    display: flex;
    margin-bottom: 40px;

    .paginationItem {
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Gotham Pro';
        font-size: 16px;
        color: #0078c8;
        font-weight: bold;

        cursor: pointer;

        // padding: 10px;

        margin-right: 20px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;

        &:hover {
            color: #ED1941;
        }

        @media (max-width: 768px) {
            font-size: 17px;
            width: 31px;
            height: 31px;

            margin-right: 15px;
        }
    }

    .next, .prev {
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        color: #0078c8;
        font-family: 'Gotham Pro';

        cursor: pointer;
    }
    .next {
        svg {
            margin-left: 10px;
            transition: 0.2s;
        }
    }
    .next:hover svg {
        transform: translateX(5px);
    }

    .prev { 
        margin-right: 15px;
        svg {
            margin-right: 10px;
            transform: rotate(180deg);
            transition: 0.2s;
        }
    }
    .prev:hover svg {
        transform: translateX(-5px) rotate(180deg);
    }

    .activePaginationItem {
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Gotham Pro';
        font-size: 16px;
        color: #333;
        font-weight: bold;

        cursor: pointer;

        // padding: 10px;

        margin-right: 20px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;

        border: 1px solid #bcddf6;;
        border-radius: 5px;

        @media (max-width: 768px) {
            font-size: 17px;
            width: 31px;
            height: 31px;

            border: none;
        }
    }
}