.labelWrapper {
    width: 100% !important;
    max-width: 700px;
    position: relative;  
    display: block; 
    height: 40px !important;

    font-family: 'Gotham Pro';
    font-weight: 400;

    border: 1px #BCDDF6 solid;
    border-radius: 5px;

    margin-bottom: 20px;

    background: #f1f5f8 right center no-repeat;

    box-sizing: border-box;
    span {
        transition: .3s;
        position: absolute;
        left: 10px !important;
        bottom: 5px;
    }
    span.label {
        bottom: 9px;
        @media (max-width: 768px) {
            font-size: 13px;
        }
    }

    span.activeLabel {
        bottom: 20px;

        color: #696969;
        font-size: 13px;
    }

    .input[type="time"] {
        width: auto;
        box-sizing: border-box;
        border: none;
        background-color: transparent;

        margin-top: -2px;
        margin-left: -40px;

        padding: 24px 50px 4px;

        outline: none;
        text-align: left;

        font-family: 'Gotham Pro';
        font-size: 13px;        

        margin-bottom: 20px;
        -webkit-appearance: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;

        }
    }
}