.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 1001;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);

    .modalContainer {
        display: flex;
        flex-direction: column;
        width: 30vw;

        background-color: #fff;
        padding: 26px;

        @media (max-width: 768px) {
            width: 70vw;
        }

        & > p {
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 30px;
            margin-top: 0;
            &:first-child {
                // margin-top: 20px;
            }
            &:last-child {
                // margin-bottom: 90px;
            }
        }

        .buttonGroup {
            display: flex;
            align-items: center;
            justify-content: flex-end;

        }

        .errorsBlock {
            display: flex;
            flex-direction: column;

            p {
                font-size: 11px;
                line-height: 14px;
                font-weight: 400;
                color: #ED1941;
                margin-bottom: 7px;
                margin-top: 0;

                &:last-child {
                    margin-bottom: 22px;
                }
            }
        }
    }
}