.PageTemplate {
    position: relative;

    min-height: 100%;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.TransparentHeader {    
    flex: 1;
    padding-top: 100px;

    background-color: transparent;
    position: relative;

    width: 100%;

    @media (max-width: 768px) {
        padding-top: 50px;
        // padding-left: 30px;
    }

    .chooseLang {
        position: absolute;
        top: 40px;
        right: 100px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &:hover span {
            transform: translateX(0);
        }

        img {
            width: 16px;
            height: 16px;

            cursor: pointer;

            background-color: inherit;


            z-index: 2;
        }

        span {
            font-size: 14px;
            padding: 0;
            margin-top: 2px;
            cursor: pointer;
            margin-right: 10px;

            color: white;

            transform: translateX(100px);
            transition: .3s;

            z-index: 1;

            &:hover {
                // color: #047ec2;
                font-family: 'Gotham Pro Bold';
            }
        }
    }
}

.NonTransparentHeader {
    // border-bottom: 1px solid #bcddf6;

    .contentWrapp {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .chooseLang {
            display: flex;
            align-items: center;
            overflow: hidden;

        z-index: 0;

            &:hover span {
                transform: translateX(0);
            }

            img {
                width: 16px;
                height: 16px;
    
                cursor: pointer;

                background-color: #fff;

                z-index: 2;
            }

            span {
                font-size: 14px;
                padding: 0;
                margin-top: 2px;
                cursor: pointer;
                margin-right: 10px;

                transform: translateX(100px);
                transition: .3s;

                z-index: 1;

                &:hover {
                    color: #047ec2;
                }
            }
        }
    }
}

