.content {
    margin-bottom: 40px;
    p, ul, li {
        font-family: "Gotham Pro";
        font-size: 14px;
        line-height: 19px;
    }

    a {
        color: #047EC2;
    }

    table {
        width: 100%;
        max-width: 600px;
        thead {
            tr {
                th {
                    border-bottom: 1px solid #0081ff;
                    margin-right: 10px;
                    width: 33%;
                    padding: 0 10px;
    
                    h5 {
                        color: #047ec2;
                        text-transform: uppercase;
                        line-height: 1.3;
                    }
    
                    &:not(:nth-child(1)){
                        text-align: center;
                    }
                    &:nth-child(1) {
                        text-align: left;
                    }
                }
            }
        }
        tbody {
            tr {
                cursor: pointer;
                &:hover {
                    background-color: rgba($color: #0081ff, $alpha: 0.1);
                }
                td {
                    border-bottom: 1px solid #0081ff;
                    margin-right: 10px;
    
                    padding: 0 10px;
    
                    h5 {
                        color: #047ec2;
                        text-transform: uppercase;
                        line-height: 1.3;
                    }
    
                    &:not(:nth-child(1)){
                        text-align: center;
                    }
                }
            }
        }        
    }
    table.two_columns {
        background-color: red;
        thead {
            tr {
                th {
                    width: 50%;
                }
            }
        }
    }
}

.linkStyles {
    display: flex;
    align-items: center;
    width: max-content !important;

    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 16px;

    color: #0078c8;

    cursor: pointer;

    @media (max-width: 1200px) {
        margin-bottom: 30px;
    }

    a {
      width: max-content !important;
    }

    &:hover svg {
      transform: translateX(10px);
    }

    svg {
      margin-left: 5px;
      fill: #8fa8b7;
      transition: 0.2s;
    }

    &:hover {
        color: #dc2832
    }
}

.variableNav {
    list-style: none;
    padding: 0;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 315px;
    width: 315px;
    box-sizing: border-box;

    position: fixed;
    top: 100px;
    right: 320px;
    padding: 28px 20px;
    background-color: rgba(#95A8B6, 0.6);
    border-right: none;
    flex-direction: column;

    border-left: 10px solid #2E74E3;

    @media (max-width: 1200px) {
        position: static;
        flex-direction: column;
        align-items: start;

        padding: 15px 0px 15px 19px;

        box-sizing: border-box;
    }
    li {
        margin-bottom: 15px;
        text-align: left !important;
        width: 100%;
        &:last-child {
            margin-bottom: 0;
        }
        
        @media (max-width: 1200px) {
            margin-bottom: 15px;
        } 
    }
    a {
        font-family: 'Gotham Pro Bold';
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;

        &.activeLink {
            color: #000000;
        }
    }
}