.head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
    @media (max-width: 1350px) {
        margin-bottom: 40px;
    }

    h3, h4 {
        margin: 0;

        display: flex;
        flex-direction: column;

        span {
            margin-top: 10px;
            color: rgb(127, 127, 127);
            font-family: 'Gotham Pro';
            font-size: 14px;

            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 13px;
            }
        }
    }

    .legendContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        width: max-content;

        @media (max-width: 1000px) {
            width: 100%;
        }

        h4 {
            margin-bottom: 20px;
        }

        .legendWrap {
            display: flex;
            flex-direction: column;
        
            margin-bottom: 20px;

            @media (max-width: 1000px) {
                margin-top: 20px;
            }
        
            .legend {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                // width: max-content;

                @media (max-width: 1000px) {
                    justify-content: flex-start;
                    flex-direction: row-reverse;
                }
        
                div {
                    margin-left: 20px;
                    border-radius: 5px;
                    @media (max-width: 1000px) {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
        
                p {
                    text-transform: uppercase;
                    @media (max-width: 1000px) {
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}

.ProgramModal,
.ProgramModalOpen {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    transition: 0.3s;

    background-color: white;

    overflow-y: auto;

    .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .closeModal {
            cursor: pointer;
    
            text-decoration: dotted;
        }
    }

    .modalBody {
        margin: 30px 0;
        // white-space: pre-wrap;   
        
        h4 {
            font-size: 30px;
            margin-top: 0;

            color: #047ec2;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                font-size: 20px;
            line-height: 1.3;
            }
        }

        p {
            font-size: 20px;
            line-height: 1.4;

            margin-bottom: 5px;
        }
    }

    
}

.cell {
    // background-color: #c1dffc;
    padding: 15px 10px;

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    font-size: 12px;

    position: relative;

    @media (min-width: 1920px) {
        font-size: 13px;
    }

    img {
        position: absolute;
        bottom: 5px;
        right: 5px;

        width: 8px;
        height: 8px
    }

    p {
        font-size: 13px;
        // line-height: 13px;
        text-align: center;
        white-space: pre-wrap;
        // word-wrap: break-word;

        
    }
}

.lottieWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 110;

    p {
        margin-top: 50px;
        font-size: 20px;
        color: #fff;
    }
}

.downloadButton {
    padding: 10px 15px;

    border: none;
    border-radius: 5px;

    background: #2e74e3;
    
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;

    cursor: pointer;

    margin-bottom: 20px;
}