.regModal {
    width: 100%;

    margin-top: 50px;
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;

    h3 {
        font-size: 30px;
        text-transform: uppercase;

        color: #047ec2;

        
    }

    

    h5 {
        color: red;
    }

    .linkGroup {
        display: flex;
        flex-direction: column;
        .checkbox {
            display: flex;
            align-items: center;
            .fakeBox {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid #797979;
                margin-right: 12px;
                cursor: pointer;
                margin-top: 0;
                margin-bottom: 12px;
            }
            .activeFakeBox {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #2E74E3;
                border: 1px solid #2E74E3;

                margin-right: 12px;
                margin-top: 0;
                margin-bottom: 12px;
                cursor: pointer;
            }
            .fakeLabel {
                font-size: 19px;
                font-weight: 400;
                color: #000000;
                cursor: pointer;
                margin-top: 0;
                margin-bottom: 10px;
            }
        }

        button {
            display: block;

            padding: 10px 20px;
            margin-right: 20px;

            border: 1px solid #047ec2;

            font-size: 20px;

            transition: .1s;

            background-color: #fff;

            cursor: pointer;

            &.active {
                background-color: #047ec2;
                color: #ffffff;
            }
        }
    }
}


.notes {
    display: block;
    max-width: 700px;

    line-height: 1.5;
    a {
        color: #0078c8;
        font-weight: 500;
    }
}

.helloUserName {
    @media (max-width: 768px) {
        margin-top: 35px;
        font-size: 20px;
    }
}
p {
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 19px;
    }
}
.typeOfRegWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    width: 100%;
    max-width: 700px;

    color: #0078c8;
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

    overflow-y: hidden;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
    }

    .activeLink {
        z-index: 2;

        border: 1px solid #BCDDF6;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        padding: 15px 10px;

        cursor: pointer;

        color: #333333;
        background-color: #fff;

        position: relative;

        img {
            width: 10px;
            height: 10px;

            background: url('../../../image/icons/arrow-down.png') center no-repeat;
            background-size: contain;

            position: absolute;
            top: calc(50% - 5px);
            right: 20px;

            transition: 0.1s;
        }
    }

    .noneActiveLink {
        display: flex;
        flex-direction: column;

        border: 1px solid #BCDDF6;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        transition: 0.1s;

        
        p {
            padding: 15px 10px;
            cursor: pointer;
            &:hover {
                color: #dc2832;
            }
        }
    }
}

.currentTypeOfRegistration {
    margin: 30px 0;

    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 30px;
    color: #ed1941;
}

.redirectQuestion {
    a {
        text-decoration: underline;
    }
}

.errorListWrapper {
    padding: 0;
    margin: 0;
    margin-top: 20px;

    list-style-type: '*';

    li {
        color: red;
        margin-bottom: 5px;
    }
}