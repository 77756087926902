h3 {
    font-size: 30px;

    color: #047ec2;

    margin: 50px 0 30px;
    @media (max-width: 768px) {
        margin-top: 0;
        font-size: 22px;
        line-height: 23px;
    }
}

.one_all_template {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
    .one_of_all_item {
        grid-area: 1 / 1 / 2 / 3;
    }

    .all_item {
        grid-area: 1 / 4 / 2 / 13;
    }
}

.one_two_template {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
    .one {
        grid-area: 1 / 1 / 2 / 4;
    }
    .two {
        grid-area: 1 / 5 / 2 / 12;
    }
}

.one_one_template {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
  .one_one_item {
    grid-area: 1 / 1 / 2 / 6;
  } 
  .one_two_item {
    grid-area: 1 / 7 / 2 / 12;
  }
}

.agreementsWrapper {
    margin-top: 20px;
    max-width: 700px;
    font-family: 'Gotham Pro';
    div {
        display: flex;
        align-items: flex-start;
        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
        .fakeInput { 
            cursor: pointer;
            img {
                width: 14px;
                height: 14px;
                margin-top: 4px;
            }
        }
        label {
            line-height: 25px;
            cursor: pointer;
            a {
                color: #0078c8;
                font-weight: 500;
            }
        }
        input {
            margin: 0;
            width: 0;
            height: 0;
            visibility: hidden;
            margin-top: 20px;
            margin-right: 10px;
        }
    }
}

.arrivalType {
    max-width: 700px;
    margin-bottom: 16px;

    p {
        margin: 0;
    }
    .item {
        display: flex;
        align-items: flex-start;
        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
        .fakeInput { 
            cursor: pointer;
            img {
                width: 14px;
                height: 14px;
                margin-top: 4px;
            }
        }
        label {
            line-height: 25px;
            cursor: pointer;
            a {
                color: #0078c8;
                font-weight: 500;
            }
        }
        input {
            margin: 0;
            width: 0;
            height: 0;
            visibility: hidden;
            margin-top: 20px;
            margin-right: 10px;
        }
    }
}

.checkboxWrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    .fakeInput { 
        cursor: pointer;
        img {
            width: 14px;
            height: 14px;
            margin-top: 4px;
        }
    }
    label {
        font-family: 'Gotham Pro';
        line-height: 25px;
        cursor: pointer;
        a {
            color: #0078c8;
            font-weight: 500;
        }
    }
    input {
        margin: 0;
        width: 0;
        height: 0;
        visibility: hidden;
        margin-top: 20px;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    input[type=checkbox] + label {
        font-size: 16px !important;
        line-height: 19px !important;
    }
}