
    p.table_item {
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 10px;

        &.small {
            color: rgb(127, 127, 127)
        }
        
        &.track {   
            font-family: 'Gotham Pro Bold';
            font-size: 10px;
            line-height: 13px;

            // font-weight: 500;

            @media (min-width: 1920px) {
                font-size: 12px;
                line-height: 14px;
            }
            @media (max-width: 1600px) {
                font-size: 9px;
                line-height: 11px;
            }
        }
        &.time {   
            font-size: 10px;
            line-height: 13px;
            color: red;
            font-weight: 400;

            @media (min-width: 1920px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

