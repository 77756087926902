@keyframes onair_wave {
    0% {
      box-shadow: 0 0 0 0 rgba(220,20,60, 0);
      
    }
    100% {
        box-shadow: 0 0 0 5px rgb(43, 255, 43, 0.3);
    }
  };

.wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
    }
    
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }


    .streamItem {
        max-width: 100%;
    }
    .video {
        width: 100%;
        
        img {
            width: 100%;
            height: 100%;
        }
    }
    p {
        display: flex;
        align-items: center;

        font-size: 20px;
        line-height: 21px;
        margin-top: 5px;

        text-transform: uppercase;
        

        img {
            margin-right: 10px;
            width: 30px;
        }
    }
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
}


.singleStreamWrap {
    display: flex;
    gap: 30px;
    width: 100%;

    margin-bottom: 20px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }

    .videoWrap {
        flex: 3;
        width: 100%;

        margin-bottom: 20px;
        @media (max-width: 768px) {
            margin-bottom: 0;
        }

        .singleVideo {
            height: 100%;
            width: 100%;
            iframe {
                width: 100%;
                // height: 100%;

                @media (max-width: 1000px) {
                    // height: auto;
                    // width: auto;
                    // height: auto;
                }
            }
        }
    }


    .commentsWrap {
        flex: 1;
        border: 1px solid rgba(4, 126, 194, 1);
        padding: 6px;
        box-sizing: border-box;

        .nonCommentatorNote {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0 10px;
            box-sizing: border-box;

            p {
                text-align: center;
                font-size: 25px;
                line-height: 28px;

                a {
                    color: #0078c8;
                    text-decoration: underline;
                }
            }
        }

        .scrolledList {
            // overflow-y: auto;
            height: 100%;
            // overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            

            .commentatorNote {
                width: 100%;
                max-height: 100%;
                display: flex;
                flex-direction: column;
    
                overflow-y: auto;

                
            }
            .createMessages {
                display: flex;
                flex-direction: column;

                position: relative;
    
                textarea {
                    margin-bottom: 10px;
                    background: #F1F5F8;
                    border-radius: 10px;
                    padding: 9px 12px;
                    padding-right: 42px;
                    border: none;

                    font-family: 'Gotham Pro';

                    resize: none;
                    height: 20px;
                }
                p {
                    align-self: flex-end;
                    text-align: right;

                    margin: 0;

                    font-size: 14px;
                    color: #00000061;
                }

                img {
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    width: 30px;

                    cursor: pointer;
                }
            }
        }
        
        
    }
}

.descriptionContainer {
    h3 {
        margin-top: 0;
    }
}

.description_wrap {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px;

    cursor: pointer;

    .description_head {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: #F1F5F8;
        padding: 10px 20px;
        padding-left: 30px;
        border-radius: 5px;
        position: relative;

        .status {
            width: 10px;
            height: 10px;
            background-color: red;
            border-radius: 50%;
            position:absolute;
            margin:auto;
            top: 15px;
            left: 10px;
        }

        .activeStatus {
            width: 10px;
            height: 10px;
            background-color: rgb(43, 255, 43);
            border-radius: 50%;
            position:absolute;
            margin:auto;
            top: 15px;
            left: 10px;
            animation: onair_wave 2s ease-out infinite;
        }

        p {
            margin: 0;
            font-size: 19px;
            line-height: 1;
        }

        img {
            width: 15px;
            position: absolute;
            top: 10px;
            right: 10px;

            transition: 0.3s;
        }
    }
    .description_body {
        background-color: #F1F5F8;
        padding: 10px 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .text_group {
            margin-bottom: 10px;
            font-size: 18px;
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }
            strong {
                margin-right: 5px;
                font-size: 18px;
            }
            span {
                line-height: 1.3;
                font-size: 18px;
            }
        }

        .title {
            margin: 0;
            margin-bottom: 10px;
            font-size: 18px;
        }

        .description_html {
            p {
                line-height: 1.3;
            }

            ul {
                padding-left: 12px;
                li {
                    line-height: 1.3;
                }
            }
        }
    }
}   