.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    background-color: #00000070;

    display: flex;
    justify-content: center;
    align-items: center;

    

    .modalContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 400px;
        padding: 10px 20px;
        
        @media (max-width: 768px) {
            margin: 10px 20px;
        }

        p {
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            margin-bottom: 30px;
        }

        .buttonGroup {
            width: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                padding: 10px 15px;
                border: none;
                border-radius: 5px;
                font-family: "Gotham Pro Regular", sans-serif;
                font-weight: 400;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;
                
                &:first-child {
                    background-color: red;
                }
                &:last-child {
                    background-color: #2e74e3;
                }
            }

            
        }
    }
}