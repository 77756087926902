.textWrapper {
    font-family: 'Gotham Pro';

    font-size: 14px;
    line-height: 19px;

    text-align: justify;

    @media (min-width: 1000px) {
        padding-right: 200px;
    }
    margin-bottom: 100px;

    h3 {
        line-height: 32px;
    }

    p {
        margin-bottom: 20px;
    }

    strong {
        font-family: 'Gotham Pro Bold';  
    }

    a {
        color: #047EC2;
    }
}