.sliderItem {
    // width: 200px;
    // height: 200px;
    img {
        width: 100%;
        max-width: 600px;
        // min-height: 100%;

        // object-fit: cover;
    }
}