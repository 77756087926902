.modalWrapper {
    position: fixed;
    top: 0;left: 0;bottom: 0;right: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;

    .modal {
        width: 400px;
        min-height: 300px;
        background-color: #fff;
        padding: 20px 20px;
        text-align: center;

        @media (max-width: 768px) {
            width: 300px;
        }

        .button_group {
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px) {
                flex-direction: column;

                button:first-child {
                    margin-bottom: 10px;
                }
            }
        }

        .error {
            color: red;
        }
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.item {
    // border-bottom: 1px solid #BCDDF6;
    padding-bottom: 13px;

    margin: 0 15px 0px 15px;

    box-sizing: border-box;

    min-height: 267px;

    @media (max-width: 768px) {
        // display: flex;
        // min-height: 0;
        // border-bottom: none;
        // padding-bottom: 0;
        margin: 0px;
        border-bottom: none;
        // margin-bottom: 13px;
        // width: 100%;
    }
    .image {
        
        width: 167px;
        min-height: 209px;

        background-size: cover;
        background-position: center top;

        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
            // height: 70px;
            // min-height: 0;

            // margin-right: 14px;
        }

        .confirmed {
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover::before {
                content: 'Профиль одобрен администратором';
                font-size: 14px;
                display: block;
                padding: 5px 8px;
                position: absolute;
                white-space: nowrap;
                bottom: -30px;
                left: 100%;
                background-color: #00000090;
                color: #ffffff;
            }
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    p {
        white-space: pre-wrap;
        max-width: 167px;
        font-size: 15px;
        margin-bottom: 0;
        color: #000000;

        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 21px;
            max-width: 1000px;
            margin: 0;
        }
    }

    span {
        font-size: 14px;
        color: #8F8F8F;


        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.error {
    color: red;
}

.deletePersone {
    margin-left: 15px;
    color: red;
    font-size: 12px;
    cursor: pointer;

    margin-bottom: 20px;

    @media (max-width: 768px) {
        margin-left: 0;
        font-size: 14px;
        padding-bottom: 19px;

        border-bottom: 1px solid #BCDDF6;
    }

    
}

.carItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #BCDDF6;
    img {
        width: 20px;
        height: 20px;
    }
    p {
        width: 30%;
        &:nth-child(2n) {
            text-align: center;
        }
        &:nth-child(3n) {
            text-align: right;
        }
    }
}

.notes {
    color: grey;
    margin-top: 30px;
    max-width: 700px;

    line-height: 20px;

    a {
        color: #047ec2;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 450px;
    height: 55px;
    max-height: 55px;

    background-color: #ffffff;
    border: 1px solid #BCDDF6;

    font-size: 16px;
    color: #ABABAB;

    font-family: "Gotham Pro Regular", sans-serif;
    font-weight: 400;

    @media (max-width: 768px) {
        width: 100%;
    }

    svg {
        margin-right: 5px;
    }

    input {
        border: none;
        outline: none;
        font-family: "Gotham Pro Regular", sans-serif;
        font-weight: 400;
        font-size: 16px;
    }
}

.buttonGroup {
    display: flex;
}

.loadFile {
    width: 0;
    height: 0;
    opacity: 0;
}

.docsWrap {
    display: flex;
    flex-direction: column;
    
    padding: 0;

    .docsItem {
        display: flex;
        align-items: center;

        &:hover .withBackground {
            background-color: rgba(221, 235, 248, 0.25);
        }
        &:hover .delete {
            display: flex;
        }

        .delete {
            display: none;
            align-items: center;

            @media (max-width: 768px) {
                display: flex;
            }

            cursor: pointer;
            span {
                line-height: 1;
                color: #ED1941;
                margin-left: 8px;
            }
        }
        .withBackground {
            display: flex;
            align-items: center;

            margin-right: 9px;
            padding: 10px 20px 10px 8px;
        }
        .decor {
            height: 8px;
            width: 8px;
            min-width: 8px;
            background-color: #047EC2;
            margin-right: 18px;
        }
        .text {
            display: flex;
            flex-direction: column;

            a {
                font-size: 14px;
                font-weight: 400;
                color: #047EC2;
                margin-bottom: 8px;
                cursor: pointer;
            }

            span {
                font-size: 12px;
                color: #ABABAB;
            }
        }
    }
}

.hotelsWrapper {
    display: flex;
    max-width: 100%;
    gap: 30px;

    margin-bottom: 80px;

    @media (max-width: 768px)   {
        flex-direction: column;
    }

    .hotelsListWrapper {
        flex: 1;
        width: 245px;
        min-width: 245px;
        max-width: 245px;

        @media (max-width: 768px)   {
            width: 100%;
            max-width: 100%;
        }
        .hotelsList {
            padding: 0;
            margin: 0;
            padding-left: 15px;
            list-style-image: url('../../../image/icons/hotel-list-marker.svg');

            li {
                padding: 11px 0 11px 10px;

                cursor: pointer;
                &:hover {
                    background: rgba(221, 235, 248, 0.25);
                }
                p {
                    font-size: 14px;
                    color: #047EC2;

                    margin: 0;
                    margin-bottom: 5px;
                }
                span {
                    font-size: 12px;
                    color: #ABABAB;
                }
            }
        }
    }

    .hotelsItem {
        flex: 2;
        width: calc(100% - 275px);
        max-width: 100%;
        a {
            font-size: 14px;
        }

        @media (max-width: 768px) {
            margin-top: 30px;
            width: 100%;
        }

        .hotelInfo {
            h5 {
                margin: 15px 0 10px 0;
                font-size: 18px;

                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }

            p {
                margin: 0;
                font-size: 16px;
                line-height: 18px;

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }


        .hostelHead {
            h4 {
                margin: 0;

                color: #047EC2;
                font-size: 20px;

                font-family: 'Gotham Pro Bold';
            }
            p {
                margin: 5px 0 14px 0;
                color: #ABABAB;
                font-size: 15px;
            }
            .itemContacts {
                display: flex;
                justify-content: flex-start;

                margin-bottom: 15px;

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 0;
                }

                button {
                    background-color: #2e74e3;
                    color: #fff;
                    border: none;

                    padding: 5px 10px;

                    cursor: pointer;

                    @media (max-width: 768px) {
                        margin-bottom: 20px;
                    }
                }

                div {
                    display: flex;
                    align-items: center;
                    margin-right: 30px;
                    @media (max-width: 768px) {
                        margin-bottom: 15px;
                    }

                    img, svg {
                        margin-right: 10px;
                        width: 20px;

                    }
                }
            }
        }

        .slider {
            // width: 100% !important;
            width: 100%;

            // @media (max-width: 1500px) {
            //     width: 100%;
            // }
        }
    }

    
}