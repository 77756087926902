.slots {
    display: flex;
    align-items: center;

    padding: 15px 0;
    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: 10px;

        li {
            margin-right: 10px;
            padding: 10px;
            // padding: 0;

            border: 1px dashed #BCDDF6
        }
    }
}

.profilesWrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    max-width: 790px;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .defaultItemWrapp {
        @media (max-width: 768px) {
            align-self: start;
        }
    }

    

    .defaultItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 0 15px 15px 15px;

        border-bottom: 1px solid #BCDDF6;

        @media (max-width: 768px) {
            flex-direction: row;
            margin: 0;
            border-bottom: none;
            margin-bottom: 50px;
        }

        .imgWrap {
            width: 167px;
            height: 207px;
            border: 1px solid #BCDDF6;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                height: 73px;
                width: 73px;
                margin-right: 14px;
            }

        }

        span {
            font-size: 15px;
            color: #8F8F8F;
            text-align: center;
            margin: 29px auto 29.5px auto;
            @media (max-width: 768px) {
                margin: 0;
            }
        }
        
    }
}