.linkStyles {
    display: flex;
    align-items: center;
    width: max-content !important;

    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 16px;

    color: #0078c8;

    cursor: pointer;

    a {
      width: max-content !important;
    }

    &:hover svg {
      transform: translateX(10px);
    }

    svg {
      margin-left: 5px;
      fill: #8fa8b7;
      transition: 0.2s;
    }

    &:hover {
        color: #dc2832
    }
}