.NavMenu {
    position: fixed;
    top: 0;
    right: 0;
    max-height: 100vh;
    height: 100vh;
    width: 306px;
    background: rgba(#0081FF, 0.7);
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 60px 0px 64px;

    box-sizing: border-box;

    transition: .3s;

    @media (max-width: 768px) {
        transform: translateX(57px);
        display: none;
    }

    ul  {
        list-style: none;
        padding-left: 0px;
        width: 100%;
    }
    .NavMenuItem {
        color: #ffffff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 23px;
        line-height: 1.1;
        letter-spacing: 1px;
    
        // margin-bottom: 10px;
        padding-left: 40px;
        padding-top: 5px;
        padding-bottom: 5px;

        position: relative;

        div {
            position: absolute;
            left: -12px;
            top: calc(50% - 8px);
        }
    }
    // .NavMenuItemWithChild {
    //     color: #ffffff;
    //     font-weight: 500;
    //     text-transform: uppercase;
    //     font-size: 23px;
    //     line-height: 1.1;
    //     letter-spacing: 1px;
    
    //      // margin-bottom: 10px;
    //      padding-left: 40px;
    //      padding-top: 5px;
    //      padding-bottom: 5px;
    //     padding-left: 40px;

    //     display: flex;
    //     align-items: center;

    //     cursor: pointer;

    //     position: relative;

    //     z-index: 2000;

    //     svg {
    //         margin-right: 10px;
    //         transform: rotate(-90deg);
    //     }

    //     .subNavMenu {
    //         position: absolute;
    //         left: calc(-100% - 20px + 1px);
    //         top: 0;

    //         padding: 10px;

    //         background-color: #1f4e9a;

    //         li {
    //             padding: 7px 15px;
    //             font-size: 15px;
    //             text-transform: none;

    //             &:first-child {
    //                 font-size: 20px;
    //                 text-transform: uppercase;
    //             }
    //         }
    //     }

    //     &:hover {
    //         background-color: #1f4e9a;
    //     }
    // }
    &:last-child {
        align-self: start;
    }
}

.programControl {
    position: fixed;
    top: 20px;
    right: 7px;

    z-index: 1000;

    cursor: pointer;

    svg {
        width: 30px;
        height: 30px;
    }
}

.disactiveMobileMenu {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 36px;
    background-color: rgba(16, 58, 141, 0.52);
    display: flex;
    justify-content: center;
    z-index: 101;
    .burger {
        // background-color: red;
        width: 18px;
        height: 18px;
        margin-top: 15px;
    }
}

.NavMenuMobile {
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #1F4E9A;

    transition: .2s;

    .chooseLang {
        position: absolute;
        top: 40px;
        right: 60px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &:hover span {
            transform: translateX(0);
        }

        img {
            width: 16px;
            height: 16px;

            cursor: pointer;

            background-color: inherit;


            z-index: 2;
        }

        span {
            font-size: 14px;
            padding: 0;
            margin-top: 2px;
            cursor: pointer;
            margin-right: 10px;

            color: white;

            transform: translateX(100px);
            transition: .3s;

            z-index: 1;

            &:hover {
                // color: #047ec2;
                font-family: 'Gotham Pro Bold';
            }
        }
    }

    .navlist {
        list-style: none;
        margin-top: 100px;
        .NavMenuItem {
            margin-bottom: 43px !important;
            text-transform: uppercase;
            a {
                font-size: 29px;
                color: #ffffff;
                font-weight: 400;

                @media (max-width: 768px) {
                    font-size: 25px !important;
                }
            }
            @media (max-width: 768px) {
                margin-bottom: 20px !important;
            }
        }
        .NavMenuItemWithChild {
            margin-bottom: 43px !important;
            text-transform: uppercase;
            display: flex;
            align-items: start;
            flex-direction: column-reverse;

            position: relative;

            svg {
                margin-right: 10px;
                transform: rotate(180deg);
            }
            a, p {
                font-size: 29px;
                color: #ffffff;
                font-weight: 400;
                margin: 0;

                @media (max-width: 768px) {
                    font-size: 25px;
                }
            }
            @media (max-width: 768px) {
                margin-bottom: 20px !important;
            }
            .subNavMenu {
                // position: absolute;
                // left: 0;
                // right: 0;
                // bottom: -100%;
                // margin-top: 20px;
                list-style: none;
                padding-left: 10px;
                padding-right: 10px;
                width: 100%;
                background-color: #fff;

                transition: 0.5s;

                // height: 0px;
                // padding: 0px;
                // margin: 0;

                display: block;

                overflow: hidden;

    
                li {
                    padding: 7px 15px;
                    font-size: 12px !important;
                    text-transform: none;
                    
                    a {
                        color: #1f4e9a !important;
                        font-size: 16px !important;

                    }
    
                    &:first-child {
                        
                        a {
                            font-size: 20px !important;
                            text-transform: uppercase; 
                        }
                    }
                }
            }
        }
    }

    .authList {
        list-style: none;
        margin-top: 90px;
        .NavMenuItem {
            margin-bottom: 43px !important;
            a {
                font-size: 29px;
                color: #ffffff;
                font-weight: 400;
            }

            
        }
    }

    .copy {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 65%;
        // position: absolute;
        bottom: 0;

        // position: absolute;
        margin-bottom: 30px;
        // bottom: 30px;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            width: 100%;
            // margin-left: 24px;
        }
        span {
            font-size: 14px;
            font-family: 'Fira Sans', sans-serif;
            color: #cce6f8;
            @media (max-width: 768px) {
                font-size: 13px;
                font-weight: 400;
                color: #fff;
            }
        }
        a {
            font-family: 'Gotham Pro';
            font-weight: 400;
            color: #cce6f8;
            @media (max-width: 768px) {
                font-size: 13px;
                font-family: 'Gotham Pro Bold';
                margin-bottom: 12px;
                color: #fff;

            }
        }
    }
    
    
}



