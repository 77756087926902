.category {
    margin-bottom: 70px;

    h4 {
        font-size: 20px;
        color: #047ec2;
    }
}

.partnersList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .partner {
        width: 100%;
        cursor: pointer;

        &:hover {
            div {
                border: 1px #dc2832 solid;
            }
            span {
                color: #0078c8;
            }
        }
        
        div {
            width: 100%;
            height: 170px;

            transition: .3s;


            background-position: center;
            background-size: 80%;
            background-repeat: no-repeat;

            border: 1px #bcddf6 solid;

            margin-bottom: 15px;
        }

        span {
            font-size: 16px;
            line-height: 18px;
            transition: .3s;

        }
    }
}

.modalPartner {
    transition: .3s;
    position: fixed;

    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;

    background-color: #fff;
}

.modalPartnerActive {
    transition: .3s;
    padding-top: 100px;
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;

    background-color: #fff;

    overflow-y: auto;

    @media (max-width: 768px) {
        padding-top: 30px;
    }

    .modalContent {
        height: 100%;
        overflow-y: auto;
        .photo {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            margin-bottom: 40px;

            img {
                width: 30%;
                border: 1px solid #ABABAB;
                border-radius: 10px;

                @media (max-width: 768px) {
                    width: 70%;
                }
            }

            svg {
                width: 30px;
                cursor: pointer;
                // margin-right: 40px;
                // margin-top: 140px;
                @media (max-width: 768px) {
                    width: 15px;
                }
            }
        }
        .info {

            .description {
                text-align: justify;

                p {
                    line-height: 1.2;
                }
                ul {
                    padding-left: 13px;

                    li {
                        font-size: 16px;
                        line-height: 1.2;

                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }
            }
            .text {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                p {
                    font-size: 18px;
                    font-family: 'Gotham Pro Bold';
                }

                a {
                    display: flex;
                    align-items: center;

                    @media (max-width: 768px) {
                        font-size: 15px;
                    }

                    img {
                        height: 18px;
                        margin-right: 10px;

                        @media (max-width: 768px) {
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
}