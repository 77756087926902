.Footer {
    width: 100vw;
    min-height: 200px;
    // padding: 0px 0 118px 118px;
    padding-bottom: 30px;

    // background-color: #1f4e9a;
    background-color: rgba(#0081FF, 1);

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    .copy {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 65%;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            width: 100%;
            // margin-left: 24px;
        }
        span {
            font-size: 14px;
            font-family: 'Fira Sans', sans-serif;
            color: #cce6f8;
            @media (max-width: 768px) {
                font-size: 13px;
                font-weight: 400;
                color: #fff;
            }
        }
        a {
            font-family: 'Gotham Pro';
            color: #cce6f8;
            @media (max-width: 768px) {
                font-size: 13px;
                font-family: 'Gotham Pro Bold';
                margin-bottom: 12px;
                color: #fff;

            }
        }
    }
}

.footerNav {
    // height: 150px;
    width: 80%;
    padding-top: 50px;

    padding-left: 0;

    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    flex-wrap: wrap;

    list-style: none;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: auto;
    }

    li {
        color: #fff;
        font-family: 'Gotham Pro Bold';
        text-transform: uppercase;

        width: max-content;
        margin-right: 20px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 20px;
            margin-bottom: 20px;
            font-family: 'Gotham Pro';
            margin-right: 0;
        }
    }
}

.socialsWrapper {
    display: flex;
    margin-bottom: 20px;

    img {
        margin-right: 15px;

        height: 15px;
    }
}