.select {
    width: 100%;
    max-width: 700px;

    box-sizing: border-box;

    padding: 24px 10px 4px;

    border: 1px #bcddf6 solid;
    border-radius: 5px;

    background: #f1f5f8 right center no-repeat;

    margin-bottom: 20px;

    position: relative;

    height: 51px;

    p {
        margin: 0;
        transition: .3s;
    }

    .clickableElement {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        cursor: pointer;

        img {
            width: 10px;
            height: 10px;

            background: url('../../../image/icons/arrow-down.png') center no-repeat;
            background-size: contain;

            position: absolute;
            top: calc(50% - 5px);
            right: 20px;

            transition: 0.1s;
        }
    }

    .activeItem {
        margin-top: 5px;
        font-size: 14px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    // .activeItemDefault {
    //     margin-top: -3px;
    //     font-size: 14px;
    // }

    .label {
        position: absolute;
        left: 10px;
        bottom: 13px;

        cursor: pointer;
    }
    
    .activeLabel {
        position: absolute;
        bottom: 27px;
        left: 10px;

        color: #696969;
        font-size: 13px;

        cursor: pointer;

    }

    .selectList {
        position: absolute;
        top: 31px;
        left: -1px;

        width: 100%;
        max-height: 167px;

        overflow-y: auto;

        padding: 0;


        list-style: none;
        background-color: #DDEBF8;
        border: 1px solid #BCDDF6;
        border-top: none;

        z-index: 2;

        border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        li {
            font-family: 'Gotham Pro';
            padding: 10px 0px 10px 13px;

            background-color: #DDEBF8;
            border-bottom: none;
            

            

            cursor: pointer;
            font-size: 14px;
            font-weight: 400;

            &:hover {
                background-color: #a6d0eb;
                color: #ffffff;
            }
        }
        .searchWrapper {
            max-width: 100%;
            width: 100%;
            height: 100%;

            // border: 2px solid #ffffff;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;

            position: relative;
            background-color: #DDEBF8;


            img {
                position: absolute;
                width: 15px;
                height: 15px;
                top: 9px;
                left: 9px;
                z-index: 100;
            }
            input {
                box-sizing: border-box;

                width: 100%;
                border: none;

                padding: 10px 0 10px 30px;

                -webkit-appearance: none;

                box-shadow: 0px 5px 21px -12px rgba(0,0,0,0.75);
                -webkit-box-shadow: 0px 5px 21px -12px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 5px 21px -12px rgba(0,0,0,0.75);

                border-radius: 0;

                position: relative;

                &::placeholder {
                    font-family: 'Gotham Pro';
                    font-weight: 400;
                    font-size: 14px;
                    color: #8F8F8F;
                }
            }
            // &::after {
            //     content: '';
            //     display: block;
            //     width: 100%;
            //     height: 3px;

            //     // background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 100%);
            // }
        }
        
    }
}

.selectDefault {
    width: 100%;
    max-width: 700px;

    box-sizing: border-box;

    padding: 11px 10px 4px;

    border: 1px #bcddf6 solid;
    border-radius: 5px;

    background: #f1f5f8 right center no-repeat;

    margin-bottom: 20px;

    position: relative;

    height: 46px;

    p {
        margin: 0;
        transition: .3s;
    }

    .clickableElement {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        cursor: pointer;

        img {
            width: 10px;
            height: 10px;

            background: url('../../../image/icons/arrow-down.png') center no-repeat;
            background-size: contain;

            position: absolute;
            top: calc(50% - 5px);
            right: 20px;

            transition: 0.1s;
        }
    }

    .activeItem {
        margin-top: 5px;
        font-size: 14px;
    }

    // .activeItemDefault {
    //     margin-top: -3px;
    //     font-size: 14px;
    // }

    .label {
        position: absolute;
        left: 10px;
        bottom: 13px;
    }
    
    .activeLabel {
        position: absolute;
        bottom: 27px;
        left: 10px;

        color: #696969;
        font-size: 13px;
    }

    .selectList {
        position: absolute;
        top: 27px;
        left: -1px;

        width: 100%;
        max-height: 200px;

        overflow-y: auto;

        padding: 0;

        list-style: none;
        background-color: grey;
        border: 1px solid #BCDDF6;
        border-top: none;


        // list-style: none;
        // background-color: grey;
        // border: 1px solid #aaaaaa;
        // border-top: none;

        z-index: 2;
        li {
            padding: 10px 0px 10px 13px;

            background-color: #F1F5F8;
            border-bottom: none;

            

            cursor: pointer;
            font-size: 14px;
            font-weight: 400;

            &:hover {
                background-color: rgba(149, 168, 182);
                color: #ffffff;
            }
        }
        .searchWrapper {
            max-width: 100%;
            width: 100%;
            height: 100%;

            border: 2px solid #ffffff;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;

            position: relative;
            input {
                box-sizing: border-box;

                width: 100%;
                border: 1px solid #aaa;
            }
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 10px;

                background-color: #aaaaaa;
            }
        }
        
    }
}