.head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 30px;

    h4 {
        font-size: 17px;
        line-height: 20px;

        color: #ED1941;

        margin: 0;
    }
}

.item {
    display: flex;
   

    // flex-grow: 3;
    // margin: 0 5px;
    flex-direction: column;

    // padding-right: 20px;
    // padding-left: 20px;

    box-sizing: border-box;

    cursor: pointer;

    margin-bottom: 20px;

    // @media (max-width: 1100px) {
    //     width: 40% !important;
    // }

    @media (max-width: 768px) {
        width: 100% !important;
    }

    & > .imgWrap {
        width: 100%;
        height: 250px;
        margin-bottom: 11px;

        img {
            // min-height: 143px;
            padding-left: 1px;
    
            width: 100%;
            height: 100%;
            object-fit: cover;
            /*   object-fit: contain; */
            object-position: center;
    
            // max-height: 201.2px;
            // margin-right: 10px;
        }

    }
    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 143px;
        margin-bottom: 11px;

        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        // margin-right: 10px;
    }

    p {
        margin: 0;
    }

    .control {
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;
        align-items: flex-start;

        max-width: 100%;

        box-sizing: border-box;

        p {
            line-height: 1;
            padding: 9px 13px;
            background-color: #047EC2;
            color: #FFFFFF;
            font-family: 'Gotham Pro Bold';
            margin-left: 1px;
        }

        .icon {
            display: flex;
            align-items: center;

            background-color: #F1F5F8;
            padding: 9px 13px;


            span {
                font-size: 14px;
                line-height: 17px;
                color: #95A8B6;

                margin-left: 9px;

            }
        }
    }

    .title {
        font-size: 16px;
        line-height: 22px;
        // text-transform: uppercase;

        color: #333;

        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}

.modalVideoWrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: rgba($color: #000000, $alpha: 0.9);

    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;

    .modalVideoContainer {
        position: relative;

        width: 67%;
        height: auto;

        .frame {
            height: 100%; 

            iframe {
                width: 100%;
                height: 100%; 
            }
        }

        @media (min-width: 768px) {
            height: 67%;
        }

        .close {
            position: relative;

            top: -20px;
            right: -100%;

            cursor: pointer;

            color: #fff;
        }
    }

}