.container {
    margin-left: 196px;
    @media (max-width: 1800px) {
        margin-left: 118px;
    }
    @media (max-width: 1000px) {
        margin-left: 30px !important;
        margin-right: 50px !important;
    }
}

.ContentContainer {
    max-width: 1500px;
    padding-right: 100px;
    @media (max-width: 1000px) {
        padding-right: 0;
    }
}