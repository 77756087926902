.slick-next {
    width: 30px;
    height: 30px;

    background: url('../../../../../image/icons/slider-arrow.svg') center no-repeat;
    background-size: contain;

    transition: .2s;
    
    right: -10px;

    z-index: 1000;

    &:hover {
        background: url('../../../../../image/icons/slider-arrow-hover.svg') center no-repeat;
        background-size: contain;
    }

    &::before {
        content: '';
    }

    &.slick-disabled {
        display: none !important;

        &:hover {
            display: none !important;
        }
    }
}

// .slick-slide {padding: 0 15px;}

// .slick-track {
//     display: flex;
// }

.photo {
    .slick-next {
        top: 30% !important; 
        right: -40px;
        @media (max-width: 1000px) {
            right: -15px;
        }
    }
}

.slick-prev {
    width: 30px;
    height: 30px;

    background: url('../../../../../image/icons/slider-arrow-prev.svg') center no-repeat;
    background-size: contain;
    transition: .2s;

    left: -50px;

    z-index: 1000;

    &:hover {
        background: url('../../../../../image/icons/slider-arrow-prev-hover.svg') center no-repeat;
        background-size: contain;
    }

    &::before {
        content: '';
    }

    &.slick-disabled {
        display: none !important;

        &:hover {
            display: none !important;
        }
    }
}

.photo {
    .slick-prev {
        top: 30% !important; 
        // right: -30px;
    }
}
