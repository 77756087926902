.wrapper {
    border-bottom: 1px solid #BCDDF6;
    padding-bottom: 30px;

    @media (max-width: 768px) {
        margin-right: 20px;
    }
}

.sliderItem {
    margin-top: 40px;
    display: flex;
    align-items: center;

    // min-height: 546px;

    @media (max-width: 1000px) {
        min-height: auto;
    }

    img {
        width: auto;
        height: 300px;

        margin-right: 20px;

        @media (max-width: 1000px) {
            width: 40%;
            height: auto;
            float: left;
        }
        @media (max-width: 768px) {
            width: 100%;
            height: auto;

            margin-bottom: 20px;
        }
    }

    .text {
        h2 {
            color: #047ec2;
            font-size: 27px;
            line-height: 32px;

            font-family: 'Gotham Pro Bold';

            margin-top: 0;

            @media (max-width: 1000px) {
                font-size: 20px;
                line-height: 22px;
            }
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
}