.wrapper {
    display: flex;
    flex-direction: column;
    .item {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1200px) {
            display: block;
        }
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .img {
            position: sticky;
            top: 0;

            @media (max-width: 1200px) {
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
            }
            @media (max-width: 768px) {
                float: none;
                margin-right: 0px;
                margin-bottom: 20px;
            }
            @media (max-width: 480px) {
                float: none;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 20px;
            }

            img {
                width: 300px;
                height: auto; 
                
                @media (max-width: 480px) {
                    width: 100%;
                    height: auto;
                }
            }
        } 

        h4, p {
            margin: 0;
        }

        h4 {
            color: #047ec2;
            font-size: 27px;
            line-height: 32px;

            font-family: 'Gotham Pro Bold';

            margin-bottom: 60px;


            @media (max-width: 1000px) {
                font-size: 25px;
                line-height: 27px;

                margin-bottom: 20px;
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;
        }
        

        .text {
            font-family: 'Gotham Pro' !important;
            width: calc(100% - 350px);

            @media (max-width: 1200px) {
                float: right;
            }
        }
    }
}