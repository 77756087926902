.labelWrapper {
    width: 100%;
    position: relative;   
    max-height: 40px;

    font-family: 'Gotham Pro';
    font-weight: 400;
    span {
        transition: .3s;
        position: absolute;
        left: 10px !important;
        bottom: 5px;
    }
    span.label {
        bottom: 7px;
        @media (max-width: 768px) {
            font-size: 13px;
        }
    }

    span.activeLabel {
        bottom: 20px;

        color: #696969;
        font-size: 13px;
    }

    input {
        width: 100%;
        max-height: 40px;

        // height: 40px;

        box-sizing: border-box;
    
        padding: 24px 10px 4px;
    
        border: 1px #BCDDF6 solid;
        border-radius: 5px;
    
        background: #f1f5f8 right center no-repeat;
    
        margin-bottom: 20px;

        -webkit-appearance: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;

        }
    }

    .error {
        // width: 100%;
        height: 20px;
        background-color: red;
        color: #ffffff;
        padding: 5px 10px;
        position: absolute;
        top: 30px;

        display: flex;
        align-items: center;
        justify-content: center;

        p {
            line-height: 16px;
            margin: 0;
        }

        &::after {
            content: '';

            position: absolute;
            top: -7px;
            left: 8px;

            display: block;
            width: 15px;
            height: 15px;

            background-color: red;

            z-index: -1;
            transform: rotate(45deg);

        }
    }
    
}



