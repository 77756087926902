.wrapper {
    position: relative;
    width: 100%;
    span {
        transition: .3s;
        position: absolute;
        left: 10px !important;
        bottom: 5px;
    }
    span.label {
        top: 16px;

        z-index: 1;
    }

    span.activeLabel {
        display: none;
        bottom: 59px;

        color: #696969;
        font-size: 13px;
        
    }

    span.activeLabel + .labelWrapper textarea {
        padding-top: 17px;
    }


    .labelWrapper {
        width: 100%;
        position: relative;   
        max-height: 40px;
        
    
        // span.activeLabel + textarea {
        //     padding-top: 10px;
        // }
    
        textarea {
            width: 100%;
            box-sizing: border-box;
        
            padding: 24px 10px 4px;
        
            border: 1px #bcddf6 solid;
            border-radius: 5px;
        
            background: #f1f5f8 right center no-repeat;
        
            margin-bottom: 20px;
    
            font: inherit;
            font-size: 13.3px;
    
            resize: vertical;
        }
    
        .error {
            // width: 100%;
            height: 20px;
            background-color: red;
            color: #ffffff;
            padding: 5px 10px;
            position: absolute;
            top: 30px;
    
            display: flex;
            align-items: center;
            justify-content: center;
    
            p {
                line-height: 16px;
                margin: 0;
            }
    
            &::after {
                content: '';
    
                position: absolute;
                top: -7px;
                left: 8px;
    
                display: block;
                width: 15px;
                height: 15px;
    
                background-color: red;
    
                z-index: -1;
                transform: rotate(45deg);
    
            }
        }
        
    }
}






