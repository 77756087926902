.newsWrapper {
    h2 {
        font-family: 'Gotham Pro Bold', sans-serif;
        font-size: 44px;
        line-height: 47px;

        color: #047ec2;

        margin-bottom: 35px;

        @media (max-width: 768px) {
            font-size: 25px;
            line-height: 28px;
        }
        
    }

    img {
        @media (max-width: 768px) {
            width: 100%;
            height: auto;
        }
    }

    .date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 35px;

        svg {
            margin-right: 10px;
        }

        span {
            font-size: 13px;
            line-height: 13px;
            font-weight: 400;
            vertical-align: middle;
            margin-top: 4px;
            white-space: nowrap;
        }
    }

    .newsBody {
        font-family: 'Gotham Pro', sans-serif;
        font-size: 17px;
        line-height: 20px;
        // text-align: justify;

        a {
            color: #0078c8;
        }
    }
}