.title {
    margin-top: 0;
    margin-bottom: 35px;

    font-family: 'Gotham Pro';
    font-size: 40px;

    color: #047ec2;

    @media (max-width: 768px) {
        font-size: 21px;
        line-height: 24px;

        margin-bottom: 27px;

    }
}