.firstScreen {
    width: 100vw;
    height: 100vh;

    background: url('../../../image/backgrounds/new-main-page-bg2.png') 35% bottom no-repeat;
    // background: url('../../../image/backgrounds/325.png') top right no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        height: 100vh;
        background: url('../../../image/backgrounds/new-main-page-mobile-bg.png') center no-repeat;
        background-size: cover;
    }
    @media (max-width: 768px) {
        height: auto;
        background: url('../../../image/backgrounds/new-main-page-mobile-bg.png') center no-repeat;
        background-size: cover;
    }

    .mobileFirstScreenContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        padding-bottom: 40px;
        box-sizing: border-box;

        @media (max-width: 1000px) {
            
        }
        // @media (max-width: 768px) {
        //     min-height: calc(100vh - 207px) !important;
        //     max-height: calc(100vh - 207px) !important;
        // }
    }

    .headerAndMap {
        display: flex;
        justify-content: space-between;
        // padding-right: 280px;
        align-items: center;
        gap: 20px;
        @media (max-width: 1650px) {
            // flex-direction: column;
            padding-right: 300px;
        }
        @media (max-width: 1600px) {
            // flex-direction: column;
        }

        img {
            flex: 1;
            margin-bottom: 20px;
            width: 80%;

            @media (max-width: 1155px) {
                display: none;
            }
        }
    }

    h1 {
        flex: 1;
        margin: 0;
        margin-top: 50px;
        width: max-content;
        // max-width: 480px;
        font-family: 'Gotham Pro';
        font-weight: 400;
        font-size: 90px;
        line-height: 93px;
        color: #ffffff;
        margin-top: 70px;
        font-family: 'Gotham Pro Medium';
        

        @media (min-width: 1500px) {
            font-size: 100px;
            line-height: 103px;
            font-family: 'Gotham Pro Medium';
            margin-top: 150px;
        }

        @media (min-width: 1650px) {
            font-size: 120px;
            line-height: 123px;
            font-family: 'Gotham Pro Medium';
            margin-top: 150px;
        }

        @media (max-width: 1000px) {
            position: relative;

            font-family: 'Gotham Pro Medium';
            color: #ffffff;

            text-transform: uppercase;
            
            padding-top: 0;
            margin-top: 70px;
            
            max-width: 100%;
            
            font-size: 55px;
            line-height: 48px;
        }

        @media (max-width: 768px) {
            position: relative;

            font-family: 'Gotham Pro';
            color: #ffffff;

            text-transform: uppercase;
            
            padding-top: 0;
            margin-top: 50px;
            
            max-width: 100%;
            
            font-size: 32px;
            line-height: 35px;
        }

    }

    .partners {
        margin-left: 30px;
        // margin-right: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 1000px) {
            flex-direction: column;

            height: auto;
            margin-left: 0;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            height: 100%;
            margin-left: 0;
        }

        @media (min-width: 1800px) {
            width: 900px;
        }
        @media (min-width: 2550px) {
            width: 1300px;
        }

        img {
            width: 140px;
            margin-right: 10px;
            transition: 0.3s;
            margin-bottom: 10px;
            @media (min-width: 1500px) {
                margin-bottom: 0;
                width: 150px;
                margin-right: 20px;
            }
            @media (min-width: 1800px) {
                margin-bottom: 0;
                width: 170px;
                margin-right: 20px;
            }
            @media (min-width: 2000px) {
                margin-bottom: 0;
                width: 300px;
                margin-right: 20px;
            }


            @media (max-width: 1000px) {
                width: 250px;
                margin-top: 20px;
            }

            @media (max-width: 768px) {
                width: 160px;
            }
        }
    }

    // svg {
    //     height: 45px;
    //     max-height: 45px;
    //     max-width: 145px;
    //     @media (max-width: 768px) {
    //         height: auto;
    //         max-height: auto;
    //         max-width: auto;
    //     }
    // }
}

.lastNewsWrapper {
    // padding-top: 50px;

    .LastNewsItem__container {
        display: flex;
        align-items: stretch;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .LastNewsItem {
        box-sizing: border-box;
        // border: 1px solid black;    
        min-height: 223px;
        padding-right: 20px;
        margin-right: 40px;

        cursor: pointer;

        font-family: 'Gotham Pro';
        font-size: 16px;
        color: #333;
        line-height: 22px;

        transition: 0.25s;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 27px;

            min-height: max-content;
        }
        
        p {
            display: block;
            margin: 0;
            // text-transform: uppercase;
            
            // padding-right: 10px;
            // text-align: justify;
            @media (max-width: 768px) {
                font-family: 'Gotham Pro';
                margin: 0 0 30px 0;
                font-size: 16px;
                line-height: 19px;
            }
        }


        

        div {
            display: flex;
            align-items: center;

            height: 20px;

            border-bottom: 1px solid #bcddf6;
            padding-bottom: 20px;;
            span {
                font-family: 'Gotham Pro';
                font-weight: 400;
                color: #696969;
                font-size: 16px;
                line-height: 18px;

                padding-top: 5px;

            }
            svg {
                margin-right: 10px;
            }
        }
        // &::after {
        //     content: '';

        //     display: block;
        //     width: 100%;
        //     height: 1px;

        //     margin-top: 20px;

        //     transition: 0.25s;

        //     background-color: #bcddf6;
        // }
        &:hover {
            color: #dc2832;

            &::after {
                background-color: #dc2832;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.LastMediaWrapper {
    padding: 100px 0px;
    // @media (max-width: 768px) {
    //     padding-left: 22px; 
    //     padding-right: 73px;
    // }
    .LastMediaItem__container {
        display: flex;
        align-items: stretch;
    }
    .LastAlbumsItem {
        width: 210px;

        margin-right: 40px;

        cursor: pointer;

        font-family: 'Fira Sans', sans-serif;
        font-size: 16px;
        color: #333;
        line-height: 25px;

        transition: 0.25s;
        a {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        img {
            width: 100%;
        }
        &::after {
            content: '';

            display: block;
            width: 100%;
            height: 1px;

            margin-top: 20px;

            transition: 0.25s;

            background-color: #bcddf6;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.firstScreenContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding-bottom: 40px;

    box-sizing: border-box;

    @media (max-width: 1000px) {
        // height: 100%;
        justify-content: space-between !important;
    }
    @media (max-width: 768px) {
        justify-content: flex-start;
    }
}

.placeWrapper {
    position: absolute;

    display: flex;
    // flex-direction: column;

    align-self: end;
    padding-right: 25px;

    // padding: 10px 25px;
    // border: 2px solid #ffffff;
    // border-right: none;
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;

    width: max-content;

    // background-color: #009fff;
    // background-color: #1F4E9A;


    @media (max-width: 1000px) {
        position: relative;
        top: auto;
        border: none;
        padding: 0;
        align-self: flex-start;
        flex-direction: column;

        // margin-left: 118px;
        background-color: transparent;
        text-align: left;
        margin-top: 70px;
    }

    @media (max-width: 768px) {
        position: relative;
        top: auto;
        border: none;
        flex-direction: column;
        padding: 0;
        align-self: flex-start;
        // margin-left: 118px;
        background-color: transparent;
        text-align: left;
    }

    span {
        color: #fff;
    }

    .date {
        font-size: 86px;
        font-family: 'Nexa Bold';
        line-height: 1;
        margin-right: 10px;

        @media (max-width: 1000px) {
            font-size: 46px;
            vertical-align: middle;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        @media (max-width: 1000px) {
            flex-direction: row;
            align-items: center;
        }

        .month {
            font-size: 40px;
            font-family: 'Gotham Pro Bold';
            line-height: 1;
        }

        .place {
            font-size: 15px;
            width: min-content;
            text-transform: uppercase;
            line-height: 1.1;
        }
    }

    // span {
    //     font-family: 'Gotham Pro Bold';
    //     color: #ffffff;
    //     font-size: 13px;
    //     text-transform: uppercase;
    //     @media (min-width: 1920px) {
    //         font-size: 22px;
    //         // font-weight: 700;
    //     }
    //     @media (max-width: 1070px) {
    //         font-size: 12px;
    //         // font-weight: 700;
    //     }
    //     @media (max-width: 768px) {
    //         font-size: 12px;
    //         font-family: 'Gotham Pro';
    //     }
    // }

    // span:first-child {
    //     font-size: 35px;
    //     margin-bottom: 15px;
    //     @media (min-width: 1920px) {
    //         font-size: 60px;
    //         // font-weight: 700;
    //     }
    //     @media (max-width: 1070px) {
    //         font-size: 35px;
    //     }

    //     @media (max-width: 1000px) {
    //         font-size: 45px;
    //         font-weight: 700;
    //     }
    //     @media (max-width: 768px) {
    //         font-size: 25px;
    //         font-family: 'Gotham Pro';
    //         font-weight: 700;
    //     }
    // }
}

.timerWrapper {
    box-sizing: border-box;

    display: flex;
    border-radius: 5px;
    opacity: 100;

    // background: linear-gradient(90deg, rgba(0, 174, 239, 0.6) 0%, rgba(0, 106, 182, 0.6) 99.98%);
    background-color: #ff0033;
    width: max-content;
    // min-width: 284px;
    padding: 20px;

    @media (max-width: 1000px) {
        // display: none;
        position: relative;
        left: auto;
        bottom: 0;
        margin-left: 0px;

        margin-top: 40px;
        max-width: max-content;
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        // display: none;
        position: relative;
        left: auto;
        bottom: 0;
        margin-left: 0px;

        margin-top: 30px;
        max-width: max-content;
        box-sizing: border-box;
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 15px;

        border-right: 3px solid #ffffff;

        @media (max-width: 768px) {
            padding: 8px 13px;
            width: max-content;
        }
        
        &:last-child {
            border-right: none;
            padding-right: 0;
        }
        &:first-child {
            // border-right: none;
            padding-left: 0;
        }

        span {
            width: 50px;
            text-align: center;
            font-family: 'Gotham Pro';
            font-size: 30px;
            color: #ffffff;
            @media (min-width: 1800px) {
                font-size: 70px;
                width: 92px;
            }
            @media (min-width: 2000px) {
                font-size: 60px;
                width: 120px;
            }
        }
        span:last-child {
            font-family: 'Gotham Pro';
            font-size: 13px;

            @media (min-width: 1800px) {
                font-size: 20px;
            }

            @media (min-width: 2000px) {
                font-size: 30px;
            }
        }
    }
}