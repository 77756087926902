.cabinetNav {
    list-style: none;
    padding: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 700px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        background-color: #F1F5F8;
        border: 1px solid #BCDDF6;

        padding: 15px 0px 15px 19px;

        box-sizing: border-box;
    }
    li {
        margin-right: 20px;
        
        @media (max-width: 768px) {
            margin-bottom: 15px;
        } 
    }
    a {
        font-family: 'Gotham Pro';
        font-weight: 400;
        font-size: 14px;
        color: #047EC2;
        cursor: pointer;

        &.activeLink {
            color: #000000;
        }
    }
}