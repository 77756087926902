.select {
    margin: 0px 15px 15px 15px;
    border: 1px solid #BCDDF6;

    font-size: 14px;

    position: relative;

    @media (max-width: 768px) {
        margin: 0 0 15px 0;
    }

    p {
        margin: 0;
        padding: 5px 5px;
        cursor: pointer;
    }

    ul {
        position: absolute;
        left: -1px;
        top: calc(100% + 1px);

        border: 1px solid #BCDDF6;
        border-top: none;

        background-color: #fff;

        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        z-index: 2;

        li {
            padding: 5px;
            &:hover {
                background-color: #BCDDF6;
                cursor: pointer;
            }
        }
    }
}