.wrapper {
    @media (min-width: 768px) {
        padding-left: 40px;
    }
    @media (max-width: 768px) {
        margin: 0 0 43px 0;
    }
    a {
        display: flex;
        align-items: center;

        svg {
            height: 40px;
            width: auto;
            fill: #ffffff;
    
            margin-right: 10px;
        }
        p {
            font-size: 17px;
            color: #ffffff;

            @media (max-width: 768px) {
                    color: #ffffff;
            font-weight: 400;
            font-size: 29px;
            margin: 0;
            letter-spacing: 1px;
            line-height: 1;
            }
        }
    }
    
}