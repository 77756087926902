@keyframes hide_message {
    0% {
        opacity: 1;
    } 25% {
        opacity: 1;
    } 50% {
        opacity: 1;
    } 75% {
        opacity: 0.6;
    } 100% {
        opacity: 0;
    }
}

.message {
    display: flex;
    flex-direction: column;

    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    // background-color: #00000035;
    border: 1px solid #002fff3e;

    max-width: 80%;
    border-radius: 10px;

    span {
        font-size: 16px;
        font-weight: 600;
        color: #047EC2;
        margin-bottom: 10px;
        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }

    p {
        margin: 0;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }
}

.messageToModerate {
    display: flex;
    flex-direction: column;

    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    // background-color: #00000035;
    border: 1px solid #002fff3e;

    max-width: 80%;
    border-radius: 10px;

    span {
        font-size: 16px;
        font-weight: 600;
        color: #047EC2;
        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }

    p {
        margin: 0;
        margin-top: 10px;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }

    .btn_group {
        display: flex;
        margin-top: 10px;
        button {
            border: none;
            color: #FFFFFF;
            padding: 5px 10px;
            border-radius: 5px;

            @media (max-width: 1500px) {
                font-size: 11px;
                line-height: 11px;
            }
            cursor: pointer;
            text-transform: uppercase;

            &:first-child {
                margin-right: 10px;
                background-color: #047EC2;
            }
            &:last-child {
                background-color: #ED1941;
            }
        }
    }
}

.chatMessage {
    display: flex;
    flex-direction: column;

    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    // background-color: #00000035;
    border: 1px solid #002fff3e;

    max-width: 80%;
    border-radius: 10px;

    span {
        font-size: 16px;
        font-weight: 600;
        color: #047EC2;
        margin-bottom: 10px;

        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }

    p {
        margin: 0;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
        @media (max-width: 1500px) {
            font-size: 13px;
            line-height: 13px;
        }
    }
}

.chatMessageToModerate {
    display: flex;
    flex-direction: column;

    padding: 10px;

    background-color: #31ff7235;

    span {
        font-size: 14px;
        color: #ffffff;
    }

    p {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
    }
}

.success {
    animation-name: hide_message;
    animation-duration: 3s;

    border: 1px solid rgba(4, 126, 194, 1);
    padding: 7px 13px;

    margin: 5px 0px;

    display: flex;
    align-items: center;

    img {
        margin-right: 6px;
    }

    span {
        font-size: 12px;
        line-height: 13px;
        color: rgba(103, 103, 103, 1);
    }
}