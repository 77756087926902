table.two_columns {
    thead {
        tr {
            th {
                width: 50%;
            }
        }
    }
}
.downloadButton {
    padding: 10px 15px;

    border: none;
    border-radius: 5px;

    background: #2e74e3;
    
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-size: 18px;
    color: #ffffff !important;

    cursor: pointer;

    margin-bottom: 20px;
}
