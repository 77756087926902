.contactsWrapper {
    width: 100%;
    max-width: 900px;

    p {
        margin: 0;
        font-size: 14px;
        line-height: 17px;

        color: #696969;
    }

    a {
        font-size: 20px;
        line-height: 23px;
        transition: 0.2s;

        &:hover {
            color: #dc2832;
        }
    }

    span {
        font-size: 20px;
        line-height: 23px;
    }
}

.emailsWrapper {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;

    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .emailItem {
        width: 35%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            width: 100%;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;
    
    
            svg {
                margin-right: 15px;
                width: auto;
            }
        }
    }
}

.phonesWrapper {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;

    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .phonesItem {
        width: 35%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            width: 100%;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;
    
    
            svg {
                margin-right: 15px;
                width: auto;
            }
        }
    }
}

.socialsWrapper {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;

    margin-bottom: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .socialsItem {
        width: 35%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}

.placesWrapper {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;

    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .placesItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        // @media (max-width: 768px) {
        //     display: block;
        // }

        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;

            @media (max-width: 768px) {
                display: block;
            }
    
    
            svg {
                margin-right: 15px;
                margin-top: 5px;
                width: auto;

                @media (max-width: 768px) {
                    float: left !important;
                }

                
            }
        }
    }
}

.mapsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .mapsItem {
        min-width: 100%;
        margin-bottom: 10px;
        iframe {
            width: 100%;
        }
    }

    margin-bottom: 20px;
}